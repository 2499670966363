<template>
  <v-card>
    <v-card-title>
      <v-btn icon>
        <column_selector
          :column_headers="headers"
          v-on:column-selection="updateHeaders"
        ></column_selector>
      </v-btn>
      {{ table_title }}

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="selectedHeaders"
      :items="items"
      :search="search"
      show-select
      :single-select="singleselect"
      v-model="selected"
      :item-key="item_key"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-switch
            v-model="singleselect"
            label="Single select"
            class="pa-3"
          ></v-switch>
          <v-toolbar-title></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <slot name="importer"></slot>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" v-on:click="$emit('edit_item', item)"
          >mdi-pencil</v-icon
        >
        <v-icon small @click="$emit('delete_item', item, selected)"
          >mdi-delete</v-icon
        >
        <v-icon
          class="ml-3"
          v-if="clipboardKey"
          small
          @click="copiedItem => null"
          v-clipboard="item[clipboardKey]"
          >mdi-content-copy</v-icon
        >
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="$emit('initialize')">Refresh</v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// import CSVImporterView from "@/components/csvData/CSVImporterView";
import column_selector from "@/components/column_selector";
export default {
  name: "AdminLayoutTable",
  components: {
    // "csv-importer-view": CSVImporterView
    column_selector
  },
  props: {
    table_title: {
      required: false,
      type: String
    },
    headers: {
      required: true,
      type: Array
    },
    items: {
      required: true,
      type: Array
    },
    item_key: {
      required: true,
      type: String
    },
    clipboardKey: {
      type: String
    }
  },
  data() {
    return {
      search: "",
      dialog: false,
      selected: [],
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0
      },
      selectedHeaders: [],
      singleselect: false
    };
  },
  methods: {
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    clear_selection() {
      this.selected = [];
    },
    save() {},
    process_data() {},
    process_import() {},
    initialize() {},
    // editItem(item) {
    //   console.log("running from child");
    //   console.log(item);
    //   this.$emit("edit_item", item);
    // },

    updateHeaders(updatedHeaders) {
      console.log(updatedHeaders);
      this.selectedHeaders = updatedHeaders;
    },
    sendToParent() {
      this.$emit("selected_items", this.selected);
    }
  },
  created() {
    // this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  computed: {
    //Done to get the ordered headers
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  watch: {
    singleselect(val) {
      if (val) {
        this.selected = [];
      }
    },
    selected: {
      deep: true,
      handler: "sendToParent"
    }
  }
};
</script>

<style scoped></style>
