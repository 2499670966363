<template>
  <div>
    <div v-if="has_unsaved_changes === true" class="unsaved">
      <p>
        <i>There are unsaved changes</i>
      </p>
    </div>
    <div class="row">
      <form
        id="point_adder_form"
        class="col-md-9 mb-2"
        @submit.prevent="addNewPoint"
      >
        <!-- <div class="col-md-1">
          <label for="new-point">Add a Point</label>
        </div> -->
        <div class="row">
          <div class="col-md-5">
            <input
              id="new-point"
              v-model.trim="newPointText"
              placeholder="E.g. /libraries/120912"
              class="add-point-title form-control"
            />
          </div>
          <div class="col-md-3">
            <input
              id="new-val"
              v-model.trim="newPointValue"
              placeholder="E.g. 0"
              class="add-point-title form-control"
            />
          </div>
          <div class="col-md-3">
            <button class="btn btn-secondary">Add Point</button>
          </div>
        </div>
      </form>

      <form
        id="point_submit_form"
        class="col-md-3"
        @submit.prevent="submitPoints"
      >
        <button type="submit" class="btn btn-primary">Save Point List</button>
      </form>
    </div>
    <div id="point-list-container" class="w-100">
      <div id="point-list-header" class="row text-center">
        <div class="col-md-5">Point Title</div>
        <div class="col-md-4">Point Value</div>
        <div class="col-md-3">Action</div>
      </div>
      <div
        is="point_item"
        v-for="(val, key) in point_map"
        :key="key"
        :title="key"
        :value="val"
        @remove="remove_point(key)"
        @save_changes="update_points"
      ></div>
    </div>
  </div>
</template>

<script>
import point_item from "@/components/point_item.vue";
/* eslint-disable-next-line */
import pointInput from "@/components/point_input.vue";

export default {
  components: {
    point_item
    // pointInput
  },
  data() {
    return {
      newPointText: "",
      nextPointIndex: 0,
      newPointValue: null,
      has_unsaved_changes: false
    };
  },
  computed: {
    point_map() {
      return this.$store.state.point_map;
    }
  },
  mounted() {
    this.$store.dispatch("get_points");
  },

  methods: {
    /**
     * add a new point to the list
     *  {
     *     id : index of the new point
     *     title : title of this step / point
     * }
     */
    addNewPoint: function() {
      let tempPointMap = {
        title: this.newPointText,
        value: this.newPointValue
      };
      console.log(tempPointMap);
      this.$store.commit("add_point", tempPointMap);
      this.has_unsaved_changes = true;
      return false;
    },
    remove_point(uid) {
      this.has_unsaved_changes = true;
      this.$store.commit("remove_point", uid);
    },
    submitPoints() {
      this.$store.dispatch("update_points");
      this.has_unsaved_changes = false;
    },
    update_points(data) {
      console.log(data);
      this.has_unsaved_changes = true;
      this.$store.commit("edit_point", data);
    }
  }
};
</script>

<style>
#point-list-header {
  background-color: rgb(170, 170, 170);
  border-radius: 0.3em;
}
#point-list-container {
  background-color: rgb(233, 233, 233);
}

.point_item {
  border: 1px solid #fff;
}
.point-create-input {
  width: 100%;
  margin-left: 2em;
  margin-right: 2em;
  text-align: center;
}

.btn.btn-secondary.point-edit-buttons {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.unsaved {
  position: absolute;
  right: 2em;
  top: 4em;
  background: #ffdede;
  padding: 0.11rem;
  border-radius: 0.4rem;
}
.unsaved p {
  margin: 0;
  padding: 0.4rem;
}
</style>
