<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
        Point Creator
      </h3>
      <h6 class="subtitle-2 font-weight-light">Create a single point</h6>
    </v-card-text>
    <v-card-text class="pa-5 border-bottom">
      <form>
        <v-row class="mt-3">
          <v-col cols="12" sm="6" lg="6">
            <v-text-field
              label="Point Title"
              hint="E.g. /libraries/120912"
              solo
              v-model="newPointText"
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <v-text-field
              v-model="newPointValue"
              label="Point Value"
              hint="E.g. 0"
              solo
              :rules="[rules.isNumber, rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-row>
      <v-col class="text-center">
        <v-btn elevation="2" color="blue darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn elevation="2" color="blue darken-1" text @click="save">
          Save
        </v-btn>
      </v-col>
    </v-row>
    <!--    <div class="pa-5 border-top text-center">-->
    <!--      <v-btn @click="save" color="blue darken-1" dark class="mr-2 text-capitalize"-->
    <!--        >Save</v-btn-->
    <!--      >-->
    <!--      <v-btn color="blue darken-1" class="text-capitalize" dark>Cancel</v-btn>-->

    <!--    </div>-->
  </v-card>
</template>

<script>
export default {
  name: "point_creator_vt.vue",
  data: () => ({
    value: "Message",
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters",
      isNumber: value => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Number is required";
      }
    },
    newPointText: "",
    newPointValue: ""
  }),
  methods: {
    save() {
      let tempPointMap = {
        title: encodeURIComponent(this.newPointText),
        value: Number(this.newPointValue)
      };
      console.log(tempPointMap);
      this.$store.commit("add_point", tempPointMap);
      // this.has_unsaved_changes = true;
      this.submitPoints();
      return false;
    },
    submitPoints() {
      this.$store.dispatch("update_points");
      // this.has_unsaved_changes = false;
      this.clearFormVals();
    },
    clearFormVals() {
      this.newPointText = "";
      this.newPointValue = "";
    }
  }
};
</script>
