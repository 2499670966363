<template>
  <v-dialog v-model="dialog" scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="primary" absolute dark v-bind="attrs" v-on="on"
        >mdi-dots-vertical</v-icon
      >
    </template>

    <v-card>
      <v-card-title>Select Active Columns</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="column_headers"
          item-key="value"
          show-select
          class="elevation-1"
          :hide-default-footer="true"
          :items-per-page="-1"
          fixed-header
        >
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!--          <v-btn color="blue darken-1" text @click="dialog = false">-->
        <!--            Copy-->
        <!--          </v-btn>-->
        <!--          <v-btn color="blue darken-1" text @click="dialog = false">-->
        <!--            Paste-->
        <!--          </v-btn>-->
        <v-btn elevation="2" color="blue darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
          elevation="2"
          absolute
          right
          color="blue darken-1"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "column_selector",
  props: {
    column_headers: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      selected: [],
      headers: [
        {
          text: "Active Columns",
          value: "text"
        }
      ]
    };
  },
  methods: {
    save() {
      this.$emit("column-selection", this.selected);
      this.dialog = false;
    }
  }
};
</script>

<style scoped></style>
