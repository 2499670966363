<template>
  <div class="d-flex justify-space-between mb-2">
    <div
      class="csv-importer-headers"
      v-for="(header, index) in import_headers"
      :key="index"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <h4 v-bind="attrs" v-on="on">
            {{ header.slug }}
          </h4>
          <small class="mb-1">
            <i>{{ header.required === true ? "Required" : "Optional" }}</i>
          </small>
        </template>
        <div class="text-center">
          <span
            v-if="header.tooltip"
            class="text-center"
            style="white-space: pre-line"
            >{{ header.tooltip }}</span
          >
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerDisplay",
  props: {
    import_headers: {
      type: Array
    }
  }
};
</script>
