<template>
  <v-container v-model="isActive" class="pa-6">
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" absolute dark v-bind="attrs" v-on="on" right>
          {{ buttonTitle }}
        </v-btn>
      </template>

      <v-card class="pa-6">
        <v-card-title>{{ buttonTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-radio-group v-model="radioGroup" row mandatory>
            <v-radio :label="'Create ' + itemType" value="enter"></v-radio>
            <v-radio label="Upload File" value="upload"></v-radio>
            <v-radio label="Paste From Clipboard" value="paste"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row justify="center">
          <v-col cols="12"> </v-col>
          <v-col v-if="radioGroup === `enter`">
            <slot name="singleItemImporter"></slot>
          </v-col>
          <v-col v-else-if="radioGroup === 'upload'">
            <header-display :import_headers="import_headers"></header-display>
            <v-file-input
              counter
              show-size
              truncate-length="25"
              placeholder="Open CSV File"
              accept=".csv"
              v-model="file"
            >
            </v-file-input>
            <v-btn
              elevation="2"
              color="blue darken-1"
              text
              @click="processData"
            >
              Process
            </v-btn>
          </v-col>
          <v-col v-else-if="radioGroup === `paste`" cols="8" class="col-auto">
            <header-display :import_headers="import_headers"></header-display>
            <v-textarea
              outlined
              label="Raw Data"
              placeholder="Paste Raw Data Here"
              :auto-grow="false"
              v-model="csvText"
              rows="5"
              dense
            ></v-textarea>
            <v-btn
              elevation="2"
              color="blue darken-1"
              text
              @click="processData"
            >
              Process
            </v-btn>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <v-row v-if="!radioGroup === `enter`">
          <v-col>
            <v-btn
              elevation="2"
              color="blue darken-1"
              text
              @click="processData"
            >
              Process
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col v-show="csvData.length > 0">
            <v-card-text style="height: auto; width: auto">
              <v-skeleton-loader
                v-if="loading === true"
                type="table"
              ></v-skeleton-loader>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="csvData"
                item-key="url"
                show-select
                class="elevation-1"
                :items-per-page="10"
              >
              </v-data-table>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-show="radioGroup === 'enter'"
          align="center"
          justify="center"
          class="text-center"
        >
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-show="csvData.length > 0">
          <v-col>
            <v-btn
              elevation="2"
              color="blue darken-1"
              text
              @click="dialog = false"
              :disabled="csvData.length === 0"
            >
              Cancel
            </v-btn>
            <v-btn
              elevation="2"
              color="blue darken-1"
              text
              :disabled="csvData.length === 0"
              @click="saveImport"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const Papa = require("papaparse");
import point_creator from "@/components/point_creator";
import point_creator_vt from "@/components/point_creator_vt";
import csv_file_input from "@/components/csv_file_input";
import HeaderDisplay from "@/components/csvData/HeaderDisplay";
import bus from "@/event_bus.js";

const moment = require("moment");

function formatPoints(val, header) {
  if (header === "title") {
    let split_title = val.split("++");
    try {
      split_title[1] = encodeURIComponent(split_title[1]);
    } catch (error) {
      error;
    }
    // let stringify = encodeURIComponent(val);
    // console.log(stringify, typeof stringify);
    return split_title.join("++");
  } else if (
    header === "activity" ||
    header === "location" ||
    header === "item"
  ) {
    return val;
  } else if (header === "date") {
    let momentObj = moment(val);
    let outputFormat = "YYYY-MM-DD";
    if (momentObj.isValid()) {
      return momentObj.format(outputFormat);
    } else {
      throw "Invalid date specified!";
    }
  } else {
    let num = Number(val);
    console.log(num, typeof stringify);
    return num;
  }
}
function formatLocation(val, header) {
  if (header === "url") {
    // let stringify = encodeURIComponent(val);
    // console.log(stringify, typeof stringify);
    return val;
  } else {
    if (parseInt(val)) {
      return parseFloat(val);
    } else {
      return val;
    }
  }
}
function formatUsers(val, header) {
  if (header === "on_leaderboard") {
    return { on_leaderboard: val };
  }
}
export default {
  name: "CSVImporterView",
  components: {
    // point_creator_vt
    headerDisplay: HeaderDisplay
  },
  props: {
    import_headers: {
      type: Array,
      required: true
    },
    buttonTitle: {
      type: String
    },
    itemType: {
      type: String
    },
    dynamicTyping: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      radioGroup: 0,
      selected: [],
      csvData: [],
      headers: [""],
      file: null,
      loading: null,
      errors: [],
      csvText: [],
      isActive: false
    };
  },
  methods: {
    save() {
      console.log(`Ready to run function on ${this.csvData}`);

      this.$emit("save-single-item", {
        csvData: this.csvData,
        csvText: this.csvText
      });
      this.csvData = "";
      this.csvText = "";
    },
    saveImport() {
      this.$emit("save-import", this.csvData);
      this.clearData();
    },
    closeWindow() {},
    formatData(val, header) {
      console.log(typeof val);
      let vals = val;
      let headers = header;
      console.log(headers);
      console.log(vals);

      if (this.itemType === "Points") {
        return formatPoints(val, header);
      } else {
        return val.toLowerCase() === "true" || val.toLowerCase() === "false"
          ? val.toLowerCase() === "true"
          : val;
      }
    },
    processData() {
      this.loading = true;
      try {
        this.data = this.headers = this.errors = [];
        let itemType = this.itemType;
        const csvdata = this.$store.state.csvData;
        // console.log(csvdata);
        setTimeout(() => {
          Papa.parse(csvdata, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: this.dynamicTyping,
            transform: function(v, k) {
              if (itemType === "Point") {
                return formatPoints(v, k);
              } else if (itemType === "Location") {
                return formatLocation(v, k);
              } else {
                return v.toLowerCase() === "true" || v.toLowerCase() === "false"
                  ? v.toLowerCase() === "true"
                  : v;
              }
            },
            complete: json => {
              if (json.errors.length == 0) {
                this.csvData = json.data;
                this.processHeaders(json.meta.fields);
              } else {
                console.log("Errors:");
                console.log(json.errors);
                this.errors = json.errors;
                this.loading = "error";
              }
            }
          });
        }, 200);
      } catch (error) {
        console.log("This error has been caught. Error message:");
        this.loading = "error";
        console.log("processed-data");
        console.error(error);
      }
    },
    processHeaders(headers) {
      try {
        headers.forEach(header => {
          this.headers.push({
            text: header.toUpperCase(),
            align: "start",
            filterable: true,
            value: header
          });
        });
        this.loading = false;
      } catch (error) {
        this.loading = "error";
        console.log(
          "This error has been caught, in processHeaders(). Error message:"
        );
        console.error(error);
      }
    },
    clearData() {
      this.csvText = "";
      this.csvData = [];
      this.file = null;
      this.$store.commit("updateCSVData", "");
    },
    cancel() {
      console.log("cancelling");
      this.dialog = false;
      this.clearData();
    }
  },
  watch: {
    file(f) {
      if (f) {
        f.text().then(data => {
          this.formatData(data);
          this.$store.commit("updateCSVData", data);
          this.processData();
        });
      }
    },
    csvText(data) {
      this.$store.commit("updateCSVData", data);
      this.processData();
    },
    radioGroup() {
      this.clearData();
    },
    isActive() {
      this.clearData();
    }
  },
  created() {
    let self = this;
    bus.$on("form-submitted", () => {
      self.clearData();
      self.dialog = false;
    });
  }
};
</script>

<style></style>
