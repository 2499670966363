<template>
  <div>
    <input
      v-model.trim="displayValue"
      type="text"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
  </div>
</template>

<script>
export default {
  props: ["value"],

  data() {
    return {
      isInputActive: false
    };
  },
  computed: {
    displayValue: {
      get: function() {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return decodeURIComponent(this.value);
        } else {
          // User is not modifying now. Format display value for user interface
          return this.value;
        }
      },
      set: function(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = encodeURIComponent(modifiedValue);

        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("input", newValue);
      }
    }
  }
};
</script>
