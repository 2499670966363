<template>
  <div v-if="local_user && local_user.user_data !== undefined">
    <b-modal id="user-editor" title="Edit Attendee" @ok="edit_user">
      <div class="m-1">
        <p>UID: {{ local_user.user_data.uid }}</p>
      </div>
      <div class="m-1">
        <p>Attendee ID: {{ local_user.user_data.event_attendee_id }}</p>
      </div>
      <div class="m-1">
        <label for="first_name" class="mr-2">First Name</label>
        <input v-model="local_user.user_data.first_name" type="text" />
      </div>
      <div class="m-1">
        <label for="last_name" class="mr-2">Last Name</label>
        <input v-model="local_user.user_data.last_name" type="text" />
      </div>
      <div class="point_list">
        <table class="table">
          <thead>
            <tr>
              <th>Point Name</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in local_user.points" :key="index">
            <td v-if="item">
              {{ item.title }}
            </td>
            <td v-if="item">
              {{ item.multiplier }}
            </td>

            <td v-if="item">
              <button class="btn btn-secondary" @click="remove_point(index)">
                Remove
              </button>
            </td>
          </tr>
        </table>
      </div>

      <div
        v-if="Object.keys(this.point_map_computed).length > 0"
        class="m-2 d-flex"
      >
        <select class="form-control" @change="select_point">
          <option disabled value="">Please select one</option>
          <option
            v-for="(point, index) in point_map_computed"
            :key="index"
            :data-index="index"
          >
            {{ index }}
          </option>
        </select>
        <button class="btn btn-secondary" @click="add_point">+</button>
      </div>

      <template #modal-footer="{ ok, cancel}">
        <div class="user-editor-modal-footer d-flex w-100">
          <div>
            <button class="btn btn-warning" @click="showMsgBox(delete_user)">
              Delete User
            </button>
          </div>
          <button class="ml-auto btn btn-secondary" @click="cancel()">
            Cancel
          </button>
          <button class="ml-1 btn btn-primary" @click="ok()">OK</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import * as fb from "../modules/firebase_helpers";
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          user_data: {},
          total_points: 0
        };
      }
    }
  },
  data() {
    return {
      local_user: {},
      selected_point: ""
    };
  },
  computed: {
    point_map_computed() {
      return this.$store.state.point_map;
    }
  },

  // watch: {
  //   userInfo(newValue, oldValue) {
  //     this.local_user = JSON.parse(JSON.stringify(newValue));
  //   }
  // },
  mounted() {
    if (
      this.point_map_computed === undefined ||
      Object.keys(this.point_map_computed).length === 0
    ) {
      this.$store.dispatch("get_points");
    }
  },
  methods: {
    edit_user() {
      this.$store.dispatch("update_user", this.local_user);
    },
    delete_user() {
      this.$store.dispatch("delete_user", this.local_user.user_data.uid);
    },
    remove_point(index) {
      Vue.delete(this.local_user.points, index);
    },
    add_point() {
      console.log(this.selected_point);
      this.local_user.points.push({
        title: this.selected_point,
        multiplier: 1
      });
      // this.$set(this.local_user.points, this.selected_point, 1.0);
    },
    select_point(event) {
      let selectedOption = event.target.selectedIndex;
      let selectedID = event.target.options[selectedOption].getAttribute(
        "data-index"
      );
      this.selected_point = selectedID;
      console.log(selectedID);
    },
    async show_modal() {
      let self = this;
      console.log("uid: ", this.userInfo.user_data.uid);
      const doc = await fb.get_user(this.userInfo.user_data.uid);
      console.log("from promise", doc.data());
      if (doc.exists) {
        self.local_user = Object.assign({}, self.local_user, doc.data());
        this.$bvModal.show("user-editor");
      }
    },
    showMsgBox(callback) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?")
        .then(value => {
          console.log("val in msgbox: ", value);
          if (value === true) {
            callback();
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err);
        });
    }
  }
};
</script>

<style></style>
