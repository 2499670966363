<template>
  <div class="point_item row  input-group">
    <div v-if="edit_mode === false" class="text-center py-2  col-md-5">
      {{ title }}
    </div>

    <div v-else-if="edit_mode === true" class="text-center py-2  col-md-5">
      <input v-model="editedTitle" class="point-create-input form-control" />
    </div>

    <div class="text-center py-2  col-md-4 point-col">
      <p v-if="edit_mode === false">{{ value }}</p>
      <input
        v-if="edit_mode === true"
        v-model="editedValue"
        class="point-create-input form-control"
      />
    </div>

    <div class="text-center py-2  col-md-3">
      <transition name="fade">
        <button
          key="1"
          class="btn btn-secondary point-edit-buttons"
          @click="edit"
        >
          {{ edit_mode === false ? "Edit" : "Cancel" }}
        </button>
      </transition>

      <transition name="fade">
        <button
          v-if="edit_mode === true"
          key="2"
          class="btn btn-secondary point-edit-buttons"
          @click="$emit('remove')"
        >
          Remove
        </button>
      </transition>

      <transition name="fade">
        <button
          v-if="edit_mode === true"
          key="3"
          class="btn btn-secondary point-edit-buttons"
          @click="save_changes"
        >
          Save Changes
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import styles from "../css/transitions.css";

export default {
  props: ["title", "value"],
  data() {
    return {
      editedTitle: this.title,
      editedValue: this.value,
      edit_mode: false
    };
  },
  methods: {
    edit() {
      this.edit_mode = !this.edit_mode;
    },
    save_changes() {
      this.$emit("save_changes", {
        title: this.editedTitle,
        value: this.editedValue
      });
      this.edit_mode = false;
    }
  }
};
</script>
