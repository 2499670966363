<template
  ><v-row
    ><v-col>
      <v-file-input
        counter
        show-size
        truncate-length="25"
        placeholder="Open CSV File"
        accept=".csv"
        v-model="file"
      >
      </v-file-input></v-col
  ></v-row>
</template>

<script>
export default {
  name: "csv_file_input",
  data() {
    return {
      file: ""
    };
  }
};
</script>

<style scoped></style>
